

.contact-box {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: #fafafa;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: var(--fourth-color);
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-sizing: border-box;
}

.contact-form {
  background-color: var(--second-color);
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, var(--first-color));
  position: absolute;
  box-sizing: border-box;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
  box-sizing: border-box;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
  box-sizing: border-box;
}



form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}

.cont-head {
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  line-height: 1;
  margin-bottom: 0.7rem;
  font-family: 'Josefin Sans', sans-serif;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.field {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: transparent;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

textarea.field {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
  font-family: 'Poppins', sans-serif;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
  font-family: 'Poppins', sans-serif;
}

.btn-contact {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: var(--first-color);
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.btn-contact:hover {
  background-color: transparent;
  color: #fff;
}
::-webkit-input-placeholder { /* Edge */
  color: #fff;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

::placeholder {
  color: #fff;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}






.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .cont-head {
  color: var(--first-color);
  position: relative;
}
.contact-info .cont-head::before {
  position: absolute;
  content: '';
  bottom: -10px;
  border-radius: 20px;
  height: 2px;
  background: #FBBC00;
  width: 50%;
}

.cont-txt {
  color: #333;
  margin: 1.5rem 0 2rem 0;
  font-family: 'Poppins', sans-serif;
  
    font-size: 15px;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
  font-family: 'Poppins', sans-serif;
   
}
.information .mail
{
  text-transform: lowercase;
}
.info
{
  width: 90%;
    background-color: #fff;
    z-index: 1;
    padding: 24px 21px;
    box-shadow: 15px 0 35px rgb(0 0 0 / 10%), 0 -15px 35px rgb(0 0 0 / 10%), 0 15px 35px rgb(0 0 0 / 10%);
}

.add-img {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
  font-family: 'Poppins', sans-serif;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, var(--second-color), var(--first-color));
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}
.social-icons a i{
  color: #fff;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid var(--first-color);
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to right, #FBBC00, #FFC527, #FFD150, #FFDD7A, #FFE8A3);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

.map-Container
{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  
}
.map-Container iframe
{
  border: none;
  width: 100%;
}
@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .cont-txt {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .contact-box {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .cont-txt,
  .information,
  .social-media p {
    font-size: 0.8rem;
    font-family: 'Poppins', sans-serif;
    
  }

  .cont-head {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .add-img {
    width: 23px;
  }

  .field {
    padding: 0.45rem 1.2rem;
  }

  .btn-contact {
    padding: 0.45rem 1.2rem;
  }
}