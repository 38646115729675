.blog-body{
    font-family: 'Josefin Sans', sans-serif;
	margin:0;
	padding:20px;
	
box-sizing: border-box;
}

.blg-row:after{
	content: '';
	display: table;
	clear: both;
}
header{

	padding:30px;
}

#line-2
{
    background: var(--first-color);
    width: 100px;
    height: 3px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

header h1{
    text-align: center;
    font-size: 3rem;
    padding: 20px 0 5px ;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    font-size: 40px; 
}
.blg-media
{
    align-items: center;
    display: flex;
    justify-content: center;
}

.blg-card h2{
	color:#000000;
margin:5px 0px;	
font-size: 30px;
text-transform: capitalize;
}
.blg-card h5{
    margin-bottom: 10px;
}
.blg-card h1{
    font-size: 30px;
    text-align: center;
}
.left-column{
float: left;
width: 70%;	
}

.right-column{
 width: 25%;
 float: right;
 padding-left:20px;
}
.blg-card{
	padding: 20px;
	background:#fff;
 margin-top:20px;
}

.blg-card img{
	width: 100%;
}
.blg-card p
{
    margin-top: 30px;
    text-indent: 30px;
    
    font-family: 'Poppins', sans-serif;
    text-align: justify;
    

}

.right-column .blg-card img{
	margin-bottom: 10px;
}


footer{
	background-color: #dddddd;
	padding: 20px;
	text-align: center;
	margin-top: 20px;
}

/*responsive */

@media(max-width:800px){
	.left-column,.right-column{
    width: 100%;
    padding: 0px;
	}
}

