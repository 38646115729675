footer{
	position: relative;
	bottom: 0;
}

@media (max-height:800px){
	footer { position: static; }
	header { padding-top:40px; }
}


.footer-distributed{
	background-color:  var(--fifth-color);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 16px 'Poppins', sans-serif;
	padding: 50px 50px 60px 50px;
	margin-top: 80px;
 
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
	width: 30%;
  text-transform: capitalize;
}

.footer-distributed h3{
  color:  var(--second-color);
	font-family:'Josefin Sans', sans-serif;
  font-size: 30px;
	margin: 0;
 
}

/* The company logo */

.footer-distributed .footer-left img{
	width: 200px;
  margin-bottom: 10px;
}

.footer-distributed h3 span{
	color:  var(--fourth-color);
  text-transform: capitalize;
  padding-left: 10px;
}

/* Footer links */

.footer-distributed .footer-links{
	color:  var(--fourth-color);
	margin: 20px 0 12px;
}

.footer-distributed .footer-links a{
	display:inline-block;
	letter-spacing: 1px;
	line-height: 1.8;
	text-decoration: none;
	color:  inherit;
	font-weight: 100;
	margin: 1px;
}

.footer-distributed .footer-company-name{
	color:  #8f9296;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

.footer-distributed .footer-company-name .developer_name{
	text-decoration: none;
	color: #fff;
	font-weight: bold;
}

/* Footer Center */

.footer-distributed .footer-center{
	width: 35%;
}


.footer-distributed .footer-center i{
	background-color:  #33383b;
	color:  var(--fourth-color);
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
	font-size: 17px;
	line-height: 38px;
}

.footer-distributed .footer-center p{
	display: inline-block;
  color:  var(--second-color);
	vertical-align: middle;
	margin:0;
}

.footer-distributed .footer-center p span{
	display:block;
	font-weight: normal;
	font-size:14px;
	line-height:2;
}

.footer-distributed .footer-center p a{
	color:  var(--second-color);
	text-decoration: none;;
}


/* Footer Right */

.footer-distributed .footer-right{
	width: 30%;
}

.footer-distributed .footer-company-about{
	line-height: 20px;
	color:  #92999f;
	font-size: 13px;
	font-weight: normal;
  text-transform: capitalize;
	margin: 0;
}

.footer-distributed .footer-company-about span{
	display: block;
  color:  var(--second-color);
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footer-distributed .footer-icons{
	margin-top: 25px;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:  #33383b;
	border-radius: 2px;

	font-size: 20px;
  color:  var(--second-color);
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}

/* Here is the code for Responsive Footer */
/* You can remove below code if you don't want Footer to be responsive */


@media (max-width: 880px) {

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
	}

}

