@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&family=Open+Sans:wght@400;500&family=Poppins:wght@300;400;500&family=Sacramento&display=swap');

  

/* width */
::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--first-color); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--second-color); 
  }

.pro-head
{
    text-align: center;
}
.pro-head h1{
   
  font-size: 3rem;
  padding: 20px 0 5px ;
  text-transform: capitalize;
  font-family: 'Josefin Sans', sans-serif;
  color: var(--first-color);
  font-weight: 600;
  font-size: 40px;
  margin-top: 50px;
}
.pro-line
{
    background: var(--third-color);
    width: 20%;
    height: 3px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}
.fab-container{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    overflow: hidden;
}

.fab-card{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 860px;
}

.fab-picBackground{
    position: relative;
    width: 50%;
    height: 525px;
    box-shadow: -15px 0 35px rgba(0, 0, 0, 0.1),
    0 -15px 35px rgba(0, 0, 0, 0.1),
    0 15px 35px rgba(0, 0, 0, 0.1);
    transition: .5s;
}

.gradients{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.gradient{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -2;
}

.first{
    z-index: 0;
    animation: 1s width ease;
}

@keyframes width{
    from{
        width: 0%;
    }
    to{
        width: 100%;
    }
}

.second{
    z-index: -1;
}

.gradient[color="blue"]{
    background-image: linear-gradient(45deg, #0136af, #22abfa);
}


.gradient[color="red"]{
    background-image: linear-gradient(45deg, #d62926, #ee625f);
}

.gradient[color="green"]{
    background-image: linear-gradient(45deg, #11998e, #1ce669);
}

.gradient[color="orange"]{
    background-image: linear-gradient(45deg, #fc4a1a, #f7b733);
}

.gradient[color="black"]{
    background-image: linear-gradient(45deg, #111, #666);
}
.gradient[color="teal"]{
    background-image: linear-gradient(45deg, teal, rgb(35, 247, 247));
}
.gradient[color="purple"]{
    background-image: linear-gradient(45deg, purple,rgb(226, 108, 226));
}

.logo-product{
    position: absolute;
    width: 150px;
    left: 20px;
    top: 20px;
}

.share{
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 1.3rem;
    text-decoration: none;
    color: var(--primary);
    transition: .5s;
}

.share:hover{
    transform: scale(1.1);
}

.share i{
    line-height: 50px;
}

.sira-transprent{
    position: absolute;
    top: 85px;
    left: 15px;
    font-size: 11rem;
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    line-height: .9;
    color: #fff;
    opacity: .1;
}
.fabric-body{
    position: relative;
}
.fabric-body .asset_fabric_two{
    position: absolute;
    z-index: -1;
    top: 0;
}
.fabric-body .asset_fabric{
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 250px;
    right: 0;
    opacity: 0.3;
}
.fab{
    position: absolute;
    width: 100%;
    opacity: 0;
    top: 30%;
    right: 0;
    transform: rotate(-20deg);
    transition: .5s;
}

.fab.show{
    opacity: 1;
}

.info{
    width: 50%;
    background-color: #FBF5B7;
    z-index: 1;
    padding: 35px 40px;
    box-shadow: 15px 0 35px rgba(0, 0, 0, 0.1),
    0 -15px 35px rgba(0, 0, 0, 0.1),
    0 15px 35px rgba(0, 0, 0, 0.1);
}

.fabName{
    padding: 0 0 10px 0;
}

.fabName div{
    display: flex;
    align-items: center;
}

.fabName div .big{
    margin-right: 10px;
    font-size: 2rem;
    color: #333;
    line-height: 1;
    font-family: 'Josefin Sans', sans-serif;
}




.fabName, .description, .color-fab-container, .size-fab-container{
    border-bottom: 1px solid #dadada;
}

.description{
    padding: 10px 0;
}

.title{
    color: #3a3a3a;
    font-weight: 600;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
}

.text{
    color: #555;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}

.color-fab-container{
    padding: 10px 0;
}
.color-container .title
{
    margin-top: 10px;
}

.colors{
    padding: 8px 0;
    display: flex;
    align-items: center;
}

.color{
    width: 25px;
    height: 25px;
    border-radius:0;
    margin: 0 10px;
    border: 5px solid;
    cursor: pointer;
    transition: .5s;
}

.color[color="blue"]{
    background-color: #000;
    border-color: #000;
}

.color[color="red"]{
    background-color: #032d49;
    border-color: #032d49;
}

.color[color="green"]{
    background-color: #626d92;
    border-color: #626d92;
}

.color[color="orange"]{
    background-color:#22292e;
    border-color: #22292e;
}

.color[color="black"]{
    background-color: #bfbfc7;
    border-color:#bfbfc7;
}
.color[color="spl-clr-1"]{
    background-color: #f13d30;
    border-color:#f13d30;
}
.color[color="spl-clr-2"]{
    background-color: #fde830;
    border-color:#fde830;
}
.color[color="spl-clr-3"]{
    background-color: #092E20;
    border-color:#092E20;
}
.color[color="spl-clr-4"]{
    background-color: #800000;
    border-color:#800000;
}
.color h4{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
}


.dia-fab-container{
    padding: 10px 0;
    margin-bottom: 10px;
}

.dias{
    padding: 8px 0;
    display: flex;
    align-items: center;
}

.dias h4
{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
}
.gsm-container
{
    padding: 10px 0;
    margin-bottom: 10px;
}
.gsm
{
    padding: 8px 0;
    display: flex;
    align-items: center;
}

.cont-price{
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.cont{
    padding: .7rem 1rem;
    background-color: var(--first-color);
    text-decoration: none;
    color: var(--fourth-color);
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
    transition: .5s;
}

.cont:hover{
    transform: scale(1.1);
}

.cont i{
    margin-right: 2px;
}

@media (max-width: 1070px){
    .fab{
        width: 135%;
    }
}

@media (max-width: 1000px){
    .fab-card{
        flex-direction: column;
        width: 100%;
        box-shadow: 0 0 35px 1px rgba(0, 0, 0, 0.2);
    }
    
    .fab-card > div{
        width: 100%;
        box-shadow: none;
    }

    .fab{
        width: 80%;
        transform: rotate(-5deg) translateY(-50%);
        top: 55%;
        right: 10%;
    }

    .sira-transprent{
        top: 20%;
        left: 5%;
    }
}

@media (max-width: 600px){
    .share{
        width: 40px;
        height: 40px;
    }

    .share i {
        font-size: 1rem;
        line-height: 40px;
    }
}

@media (max-width: 490px){
    .sira-transprent{
        font-size: 7rem;
    }

    .fabName div .big{
        font-size: 1.3rem;
    }

    .small{
        font-size: 1rem;
    }

    .new{
        padding: 2px 6px;
        font-size: .9rem;
    }

    .title{
        font-size: .9rem;
    }

    .text{
        font-size: .8rem;
    }

    .cont{
        padding: .5rem .8rem;
        font-size: .8rem;
    }

    
.dias h4
{
   font-size: 15px;
}

    .color{
        margin: 0 6px;
        width: 0 20px;
        width: 20px;
        height: 20px;
        border-width: 4px;
    }

    .share{
        width: 35px;
        height: 35px;
        top: 10px;
        right: 10px;
    }

    .share i{
        font-size: .8rem;
        line-height: 35px;
    }


    .info{
        padding: 20px;
    }
}

@media (max-width: 400px){
    .cont i{
        display: none;
    }

    .fab-container{
        padding: 20px;
    }
}