

  

  
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--first-color); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--second-color); 
  }
  .about_all_container{
    overflow-x: hidden;
  }
.about-banner img
  {
    width: 100%;
    height: auto;
    filter: blur(1.1px);
  -webkit-filter: blur(1.1px);
  
    
  }
.container-ban
{
    position: relative;
    text-align: center;
    color: white;
    margin-bottom: 20px;
   
}

  .ban-content
  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2rem;
    font-weight: bold;
    
  }


.about-text-container
{
 position: relative;
 width: 95%;
 margin-left: auto;
 margin-right: auto;
 height: 100%;
 word-wrap: break-word;

}

.about-text-container p{
  text-align: justify;
  font-family: 'Poppins', sans-serif;
  text-indent: 50px;
  word-wrap: break-word;
}
.abt-first
{
    margin-top: 80px;
}
.abt-head h1{
  text-align: center;
  font-size: 3rem;
  padding: 20px 0 5px ;
  text-transform: capitalize;
  font-family: 'Josefin Sans', sans-serif;
  color: var(--first-color);
  font-weight: 600;
  font-size: 40px
}
.abt-head h3{
  text-align: center;
  font-size: 15px;
  padding: 20px 0 5px ;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  color: var(--third-color);
  opacity: 0.5;
  font-weight: 400;
  letter-spacing: 10px;
}
#line
{
  background: var(--third-color);
  width: 20%;
  height: 3px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}


.about-text-container h1
{
  font-family: 'Josefin Sans', sans-serif;

  font-size: 30px;
} 
.about-text-container .image-about-1{
width: 100%;

}
#line-min
{
  background: var(--third-color);
  width: 100px;
  height: 3px;
  display: block;
}

.swiper-2
{
  width:50%;
  float: left;
  padding: 20px;
}
.image__wrapper-2
{
  width: 100%;

  position: relative;
  padding-top: 56.25%;
}
.image__wrapper-2 img
{
  position: absolute;
  top: 0;
object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center top;
}
.container-back
{
  background: rgb(251,188,0);
  background: linear-gradient(180deg, rgba(251,188,0,1) 0%, rgba(246,193,33,0.9528186274509804) 35%, rgba(255,166,0,1) 100%);
    width: 100%;
    height: 70vh;
}

.asset_grow{
position: absolute;
bottom: 0;
height: 100%;
opacity: 0.3;
z-index: 0;
width: 50%;
right: 0;
}
.swipe-container-2 {
   
  display: grid;
  place-items: center;
  min-height: 100vh;
  
}

.box-2 {
    background-color: transparent;
    border-radius: 3px;
    color: var(--third-color);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 300px;
    transform-style: preserve-3d;
    perspective: 2000px;
    transition: 0.4s;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
   
}
.box-2:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-top: 20px solid #fff;
    border-left: 20px solid #fff;
    box-sizing: border-box;
}
.box-2:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 20px solid #fff;
    border-right: 20px solid #fff;
    box-sizing: border-box;
}
.box-2 i {
    font-size: 25px;
    height: 50px;
    width: 50px;
    line-height: 50px !important;
    background-color: #fff;
    color: #2c3a47;
}
.box-2 .fa2 {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
}
.box-2 .text {
    position: absolute;
    top: 30px;
    left: -30px;
    width: calc(100% + 60px);
    height: calc(100% - 60px);
    background-color: var(--fifth-color);
    border-radius: 3px;
    transition: 0.4s;
    
 
}
.box-2 .text .fa1 {
    position: absolute;
    top: 0;
    left: 0;
}
.box-2 .text .txt-cont {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    padding: 30px 60px;
    line-height: 1.5;
    box-sizing: border-box;
}
.box-2 .text .txt-cont h3 {
    font-size: 30px;
    margin-bottom: 5px;
    font-family: 'Josefin Sans', sans-serif;
    color: var(--fourth-color);
}
.box-2 .text .txt-cont p {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    color: var(--fourth-color);
}
.box-2:hover {
    transform: translate(-50%, -50%) rotateY(-20deg) skewY(3deg);
}
.box-2:hover .text {
    transform: rotateY(20deg) skewY(-3deg);
}
.txt-cont #line-2
{
    background: var(--fourth-color);
    border: var(--fourth-color);
    width: 50%;
    height: 1px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 10px;
}

.head
{
    text-align: center;
    font-size: 3rem;
    padding: 20px 0 5px ;
    text-transform: capitalize;
    font-family: 'Josefin Sans', sans-serif;
    color: var(--thired-color);
    font-weight: 600;
    font-size: 40px
}
#line-3
{
    
    background: var(--third-color);
    width: 20%;
    height: 3px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}
.strengths p{
    font-size: 15px;
    color:var(--third-color);
    font-family: 'Poppins', sans-serif;
    line-height: 1.8;
    text-align: center;
    margin-top: 20px;
    
}

.strengths {
    padding-right: 70px;
    padding-left: 70px;
    margin-right: auto;
    margin-left: auto;
    width: 70%;
    
    
  }
  .strengths .our_strenght{
      margin-top: 20px;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

.fabricProcess{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.fabricProcess .processOne,.fabricProcess .processTwo{
  display: flex;
  font-family: 'Poppins', sans-serif;
}
.fabricProcess .processOne .process_img_container_one,.fabricProcess .processTwo .process_text_container_two{
flex: 1;
padding: 20px;
position: relative;

}
.fabricProcess .processTwo .process_img_container_two{
flex: 1;
padding: 20px;
}
.fabricProcess .processOne .process_img_container_one::after
{
  content: '';
position: absolute;
height: 100%;
width: 2px;
background-color: #FFD900;
right: 0;
top: 0;

}
.fabricProcess .processTwo .process_text_container_two::before
{
  content: '';
position: absolute;
height: 100%;
width: 2px;
background-color: #FFD900;
right: 0;
top: 0;

}
.fabricProcess .processOne .process_img_container_one img,.fabricProcess .processTwo .process_img_container_two img{
  width: 100%;
}
.fabricProcess .processOne .process_text_container_one,.fabricProcess .processTwo .process_text_container_two{
  flex: 1;
  }
  .process_text_container_inner{
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }
.process_text_container_one,.process_text_container_two{
  padding: 20px;
  flex: 1;
 
}
.process_text_container_inner .process_head{
  margin-bottom: 20px;
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}
.container-back-2
{
    display: flex;
    align-items: center;
    background:var(--first-color);
    flex-wrap: wrap;
    
}
.customer
{
    flex:1 1 30%;
   
}
.customer img
{
    width: 100%;
}
.customer-section
{
  
  overflow: hidden;
  display: flex;
}
.customer-section .row{
  display: flex;
  align-items: center;
  background:linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
  flex-wrap: wrap;
  padding: 50px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.customer-section .row .cus-image{
  flex:1 1 30%;
}

.customer-section .row .cus-image img{
  width: 100%;
}
.customer-section .row .content{
  flex:1 30%;
  padding:1rem 10px;
}

.customer-section .row .content h1{
  font-size: 40px;
  color:var(--first-color);
  font-family: 'Josefin Sans', sans-serif;
  text-align: center;
}
.customer-section .row .content p{
    font-size: 15px;
    color: var(--first-color);
    font-family: 'Poppins', sans-serif;
    padding:10px 0;
    line-height: 1.8;
    text-align: justify;
    
}
 
@media(max-width:720px)
{
  .about-text-container p{
    font-size: 14px;
  }
    .abt-head h1
    {
        font-size: 2rem;
    }
    .abt-head h3
    {
        font-size: 15px;
    }
    .head
    {

        font-size: 2rem;
    }
    .customer-section .row .image{
        flex:1 1 50%;
      }
      
      
      .customer-section .row .content{
        flex:1 50%;
        padding:30px;
      }
    
      
      
}



@media (max-width: 730px)
{
    .asset_grow{
display: none;
    }
    .customer-section .row .cus-image{
        flex:1 1 50%;
      }
      
      .customer-section .row .cus-image img{
        width: 100%;
      }
      .customer-section .row .content{
        flex:1 50%;
        padding:1rem 10px;
      }
    
     
}

@media (max-width: 480px)
{
  .abt-part-2{
    display: none;
  }
.strengths .our_strenght{
  box-shadow: none;
}
    .ban-content
    {
        font-size: 1rem;
    }
    
    .box {
        
        
        width: 250px;
        height: 250px;
    }
    .box .text .txt-cont {
       
        width: 100%;
        padding: 30px 60px;
        line-height: 1.1;
        
    }
    .box .text .txt-cont p {
        font-size: 10px;
    }
    .box .text .txt-cont h3
    {
        font-size: 20px;
    }
    .strengths {
        padding-right: 0;
        padding-left: 0;

      }
      .customer-section
      {
          overflow: hidden;
      }  
      .swiper-2
      {
        padding: 0;
      }
      .about-text-container p{
        padding: 20px;
      }
    }   


@media (max-width: 320px) 
{
 

    .box {
        
        
        width: 200px;
        height: 200px;
    }
    .box .text .txt-cont {
       
        width: 100%;
        padding: 30px 60px;
        line-height: 1.1;
        
    }
    .box .text .txt-cont p {
        font-size: 7px;
    }
    .box .text .txt-cont h3
    {
        font-size: 20px;
    }

}
@media (max-width: 768px) {
    .about-text-container,.strengths  {
      width: 100%;
    }
    .swiper-2{
      width: 100%;
    }
    .strengths {
      padding: 0;
    }
    .strengths p{
      padding: 10px;
    }
    .fabricProcess .processOne,.fabricProcess .processTwo{
      flex-direction: column;
    }
    .fabricProcess .processOne .process_img_container_one::after
{
  display: none;

}
.fabricProcess .processTwo .process_text_container_two::before
{
display: none;

}
  }
  @media (max-width: 992px) {
    .about-text-container,.strengths  {
      width: 100%;
    }
    .about-text-container,.strengths  {
      width: 100%;
    }

   
  }
  
  

