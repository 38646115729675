
@media (min-width: 1000px) {
    #timeline .demo-card:nth-child(odd) .head::after, #timeline .demo-card:nth-child(even) .head::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
   }
    #timeline .demo-card:nth-child(odd) .head::before, #timeline .demo-card:nth-child(even) .head::before {
        position: absolute;
        content: "";
        width: 9px;
        height: 9px;
        background: radial-gradient(circle, #FBBC00, #FFD900);

        border-radius: 9px;
        box-shadow: 0px 0px 2px 8px #f7f7f7;
        cursor: pointer;
   }
}
/* Some Cool Stuff */
.demo-card:nth-child(1) {
    order: 1;
}
.demo-card:nth-child(2) {
    order: 4;
}
.demo-card:nth-child(3) {
    order: 2;
}
.demo-card:nth-child(4) {
    order: 5;
}
.demo-card:nth-child(5) {
    order: 3;
}
.demo-card:nth-child(6) {
    order: 6;
}
/* Border Box */
.ache-container {
    box-sizing: border-box;
    position: relative;
}
.asset_back_yarn_two{
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    opacity: 0.3;
}

.asset_fabric_two{
    position: absolute;
    width: 300px;
    left: 30px;
    top: 8%;
    opacity: 0.3;
}
.acheivement_back{
    position: absolute;
    width: 300px;
    left: 0;
    opacity: 0.3;
    top: 50%;
    transform: rotate(20deg);
}
.asset_cottonmills{
    position: absolute;
    opacity: 0.5;
    width: 300px;
    bottom: 0;
    right: 50px;
}
.asset_back{
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 50%;
    opacity: 0.3;
    
}
/* Fonts */

#timeline {
    padding: 100px 0;
}
#timeline h1 {
    text-align: center;
    font-size: 3rem;
    padding: 20px 0 5px ;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
     
}
#timeline p.leader {
    text-align: center;
    max-width: 90%;
    font-family: 'Poppins', sans-serif;
    margin: auto;
    font-size: 1rem;
    color: var(--first-color);
    letter-spacing: 5px;
    margin-bottom: 45px;
    margin-top: 20px;
    font-weight: 500;
}
#timeline .demo-card-wrapper {
    position: relative;
    margin: auto;
}
@media (min-width: 1000px) {
    #timeline .demo-card-wrapper {
        display: flex;
        flex-flow: column wrap;
        width: 1170px;
        height: 1650px;
        margin: 0 auto;
   }
}
#timeline .demo-card-wrapper::after {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 1px solid rgba(191, 191, 191, 0.4);
}
@media (min-width: 1000px) {
    #timeline .demo-card-wrapper::after {
        border-left: 1px solid #AA771C;
   }
}
#timeline .demo-card {
    position: relative;
    display: block;
    margin: 10px auto 80px;
    max-width: 94%;
    z-index: 2;
}
@media (min-width: 480px) {
    #timeline .demo-card {
        max-width: 75%;
        box-shadow: 0px 1px 22px 4px rgba(0, 0, 0, 0.07);
   }
   .story h1 {
    font-size: 1.5rem;
  }
}
@media (min-width: 720px) {
    #timeline .demo-card {
        max-width: 50%;
   }
}
@media (min-width: 1000px) {
    #timeline .demo-card {
        max-width: 450px;
        height: 400px;
        margin: 90px;
        margin-top: 45px;
        margin-bottom: 45px;
   }
    #timeline .demo-card:nth-child(odd) {
        margin-right: 45px;
   }
    #timeline .demo-card:nth-child(odd) .head::after {
        border-left-width: 15px;
        border-left-style: solid;
        left: 100%;
   }
    #timeline .demo-card:nth-child(odd) .head::before {
        left: 491.5px;
   }
    #timeline .demo-card:nth-child(even) {
        margin-left: 45px;
   }
    #timeline .demo-card:nth-child(even) .head::after {
        border-right-width: 15px;
        border-right-style: solid;
        right: 100%;
   }
    #timeline .demo-card:nth-child(even) .head::before {
        right: 489.5px;
   }
    #timeline .demo-card:nth-child(2) {
        margin-top: 180px;
   }
}
#timeline .demo-card .head {
    position: relative;
    display: flex;
    align-items: center;
    
    font-weight: 400;
}
#timeline .demo-card .head span{
    color: #fff;
}

#timeline .demo-card .head .number-box {
    display: inline;
    float: left;
    margin: 15px;
    margin-right: 0;
    padding: 10px;
    font-size: 35px;
    line-height: 35px;
    font-weight: 600;
    background: #363636;
}
#timeline .demo-card .head h2 {
    color: #363636;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: inherit;
    letter-spacing: 2px;
    margin: 0;
    padding-bottom: 6px;
    font-family: 'Josefin Sans', sans-serif;
   
    line-height:30px;
    width: 100vw;
}
@media (min-width: 480px) {
    #timeline .demo-card .head h2 {
      
        line-height:15px;
       
   }
}
#timeline .demo-card .head h2 span {
    display: block;
    font-size: 15px;
    margin: 0;
}
@media (min-width: 480px) {
    #timeline .demo-card .head h2 span {
        font-size: 15px;
   }
}
#timeline .demo-card .body {
    background: #FFD900;
    border: 1px solid rgba(191, 191, 191, 0.4);
    border-top: 0;
    padding: 15px;
}
@media (min-width: 1000px) {
    #timeline .demo-card .body {
        height: 315px;
   }
}
#timeline .demo-card .body p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
    
}
#timeline .demo-card .body img {
    display: block;
    width: 100%;
}
#timeline .demo-card--step1 {
    background: linear-gradient(to top, #FBBC00, #FFD900);
}
#timeline .demo-card--step1 .head::after {
    border-color: #FFD900;
}
#timeline .demo-card--step2 {
    background: linear-gradient(to top, #FBBC00, #FFD900);
}
#timeline .demo-card--step2 .head::after {
    border-color: #FFD900;
}
#timeline .demo-card--step3 {
    background: linear-gradient(to top, #FBBC00, #FFD900);
}
#timeline .demo-card--step3 .head::after {
    border-color: #FFD900;
}
#timeline .demo-card--step4 {
    background: linear-gradient(to top, #FBBC00, #FFD900);
}
#timeline .demo-card--step4 .head::after {
    border-color: #FFD900;
}
#timeline .demo-card--step5 {
    background: linear-gradient(to top, #FBBC00, #FFD900);
}
#timeline .demo-card--step5 .head::after {
    border-color: #FFD900;
}

