.infra-head h1
{
    text-align: center;
    font-size: 3rem;
    padding: 20px 0 5px ;
    margin-top: 20px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    font-size: 40px; 
}
.infra-text p{
    text-align: center;
    padding-left: 70px;
  padding-right: 70px;
  margin-right: auto;
  margin-left: auto;
    font-family: 'Poppins', sans-serif;

    font-size: 15px;
    margin-top: 30px;
    width: 80%;
    margin-bottom: 30px;
}
.infra-head #line-2
{
    background: var(--first-color);
    width: 100px;
    height: 3px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
.infra-container
{
  padding:2rem 4%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.infra-box
{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 150px;
    flex-wrap: wrap;
}
.infra-box .infra-image
{
    flex: 1 1 40%;
}
.infra-box .content
{
    flex: 1 1 20%;
    margin: 30px;

}
.infra-box .infra-image .image-infra-2
{
    width: 100%;
}
.infra-box .content h3
{
    font-size: 50px;
    font-family: 'Josefin Sans', sans-serif;
   color: var(--first-color);
}

.infra-box-2
{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 150px;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}
.infra-box-2 .infra-image
{
    flex: 1 1 40%;
}
.infra-box-2 .content
{
    flex: 1 1 20%;
    margin: 30px;

}
.infra-box-2 .infra-image .image-infra-2
{
    width: 100%;
}
.infra-box-2 .content h3
{
    font-size: 50px;
    font-family: 'Josefin Sans', sans-serif;
    color: var(--first-color);
}

@media (max-width: 995px){
    .infra-box .infra-image{
        flex:1 1 50%;
        
      }
      .infra-box .infra-image .image-infra-2
      {
      width: 100%;
      }
      .infra-box .content{
        flex:1 50%;
        text-align: center;
        
      }
      .infra-box-2 .infra-image{
        flex:1 1 50%;
        
      }
      .infra-box-2 .infra-image-2 .image-infra-2
      {
      width: 100%;
      }
      .infra-box-2 .content{
        flex:1 50%;
       text-align: center;
      }
}

@media (max-width:480px) {
    .infra-box .content h3
{
    font-size:30px;
}
.infra-box-2 .content h3
{
    font-size:30px;
}
.infra-text p{
    
    padding-left: 20px;
  padding-right: 20px;
}
}