*
{
    margin: 0;
    padding: 0;
}
.gallery-back
{
    width: 99%;
    height: 100%;
}
.gallery-body {
    column-count: 5;
    column-gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .gallery-body a {
    text-decoration: none;
  }
  
  .gallery-body img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* .gallery-body a:hover img {
    transform: scale(1.1);
  } */
  
  .gallery-body a:focus {
    outline: none;
  }
  
  @media (max-width: 768px) {
    .gallery-body {
      column-count: 3;
    }
  }
  @media (max-width: 668px) {
    .gallery-body {
      column-count: 2;
    }
  }
  @media (max-width: 480px) {
    .gallery-body {
      column-count: 1;
    }
  }