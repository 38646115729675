

  
  *
  {
      padding: 0;
      margin: 0;
      
  }



.top-bar
{
  width: 100%;
  height: 15px;
  padding:10px;
overflow-x: hidden;
  background:var(--first-color);
}
.top-bar span{
  color: var(--fourth-color);
  font-family: 'Poppins', sans-serif;
}
.top-bar a i{
  margin-right: 5px;
}
.top-bar ul
{
  padding-right: 20px;
  list-style: none;
  display: flex;
  float: right;
}
.top-bar li{
  margin: 0px 5px;
}
.top-bar a
{
  color: var(--fourth-color);
  text-decoration: none;
  font-family: 'Poppins', sans-serif;

  
}
.top-bar a i 
{
  padding-right: 10px;

}
.logo
{
    width: 175px;
    cursor: pointer;
    position: relative;
    margin: 20px;
}
.top-bar span:hover
{
  color: #BF953F;
}

nav{
  display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
  height: 80px;
  background: #fff;
border-bottom: 1px solid lightgray;
 
  
}
.t-scroll
{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  top: 0;
  z-index: 10000;
}
.menu span.active {
  color:  var(--fourth-color);
}

/* Hide the link that should open and close the topnav on small screens */
.menu .icon {
  display: none;
}


.menu li
{
    display: inline-block;
    margin: 10px 20px;
    list-style: none;
    position: relative;
    cursor: pointer;
    
}
.menu li span,.menu li a
{
    text-decoration: none;
    color: var(--third-color);
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;
}
.menu li span::after
{
    content: '';
    height: 3px;
    width: 0%;
    background:  var(--first-color);
    position: absolute;
    bottom: -10px;
    left: 0;
    transition: 0.5s;
    

}
.menu li span:hover::after,
.menu li .active::after{
    width: 80%;
    border-radius: 30px;
}




nav .contact
{
    margin: 10px 20px;
    position: relative;
    padding: 12px 20px;
    display: flex;
    text-decoration: none;
    text-transform: capitalize;
    overflow: hidden;
    border-radius: 40px;
    border: none;
    cursor: pointer;

}

.drp-down {
  display: none;
  position: absolute;
  background: #fff;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 0;
  z-index: 1000000;
  margin-top: 1px;
  left: -20px;
  text-transform: capitalize;
}
.menu li:hover .drp-down {
  display: flex;
  flex-direction: column;
}
.menu li:hover .fa-caret-down
{
  transform: rotate(180deg);
}

.dropdown:hover .dropdown-content {
  display: block;
}
.nav_third_part{
  background: #FBBC00;
  width: 25vw;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: relative;
  justify-content: space-around;
}
.nav_third_part::before
{
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px;
  background-color: #FBBC00;
  width: 100px;
  z-index: -1;
  transform: skew(20deg);
}
.call_icon_container{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call_text_container{
  flex: 2;
}
.call_text_container p{
  font-size: 25px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
color: #f1f0f0;
}
.call_text_container h4 a{
  text-decoration: none;
  color: #f1f0f0;
}
.call_icon{
  width: 50px;
}
  .hamburger{
    display: none;
  }
   
   @keyframes animate {
    0% {
     transform: translate(-50%, -75%) rotate(0deg);
    }
   
    100% {
     transform: translate(-50%, -75%) rotate(360deg);
    }
   }
      

@media (max-width: 995px)
{
.top-bar,.nav_third_part
{
  display: none;
}
.menu li span:hover::after,
.menu li span.active::after{
    display: none;
}

.menu li span:hover,
.menu li .active{
    color: #FBBC00;
}
 nav{
   height:75px;
   padding: 0px;
  
 }

 nav{
   flex-direction: row;
 }
 nav .contact{
  display: none;
}

 nav ul
 {
 position: fixed;
 top: 80px;
 left: 0;
 display:block;
 background: var(--second-color);
 width: 100%;
 
 }
 .active {
  color: var(--first-color);
}
 .menu{
  position: fixed;
  top: 0;
  left: -100%;
  display: block;
  max-width: 200px;
  width: 100%;
  height: 100vh;
  background: #fff;
  line-height: 40px;
  padding: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;
  color: var(--first-color);
  z-index: 100000;
 }
 .menu .drp-down 
 { 
   position: relative;
   top: 0;
   box-shadow: none;
   display: none;
   background: transparent;
 }
 .menu .drp-down ul li
 {
  margin-bottom: 20px;
    width: 100%;
    text-align: center;
 }
.show
{
  left: 0;
}

 nav ul li
 {
   width: 100%;
   margin: 40px 0;
 }

 .menu li span:hover::after
 {
   opacity: 0;
 }
 nav ul li a
 {
 width: 100%;
 text-align: center;
 display: block;
 font-size: 20px;
 transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    margin: auto;

 }
 nav ul li span:hover
 {
   color: var(--first-color);
 }

 .hamburger{
 height: 60px;
 width: 60px;
 font-size: 30px;
 display: inline-block;
 border: 2px solid var(--first-color);
 border-radius: 50%;
 position: relative;
 display: flex;
 align-items: center;
 justify-content: center;
 z-index: 100;
 cursor: pointer;
 transform: scale(.8);
 margin-right: 20px;
 
 }

 .hamburger::after
 {
 position: absolute;
 content: '';
 height: 100%;
 width: 100%;
 border-radius: 50%;
 border: 2px solid var(--first-color);
 animation: hamburger_puls 1s ease infinite;
 }
 @keyframes hamburger_puls {
0% {
 opacity: 1;
 transform: scale(1);
}
100% {
 opacity: 0;
 transform: scale(1.4);
}
}
 .hamburger:hover
 {
   color: var(--first-color);
 }

 nav
 {
   overflow: hidden;
 }
 .call
 {
   display: none;
 }
 nav .menu .active{
   display: block;
 }
}

@media (max-width:480px) {
  .logo
  {
    height: 30px;
  }
}